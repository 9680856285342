import Layout from "../layouts";
import React from "react";
import { StaticImage } from "gatsby-plugin-image";

const AboutPage = () => (
  <Layout>
    <p className="leads-the-things emboldernised">
      Who you could be working with...
    </p>

    <p>
      We're a couple of software engineers from the UK. In 2016 we decided to
      leave our comfortable lives behind, travel the world, and explore our own
      dreams.
    </p>

    <ul className="all-the-cats">
      <li className="cat">
        <div className="cat__image">
          <StaticImage src="../assets/images/people/james.jpg" alt="James" />
        </div>
        <h4>James</h4>
        <p>Co-founder and developer</p>
      </li>
      <li className="cat">
        <div className="cat__image">
          <StaticImage
            src="../assets/images/people/danielle.jpg"
            alt="Danielle"
          />
        </div>
        <h4>Danielle</h4>
        <p>Co-founder and developer</p>
      </li>
      <li className="cat">
        <div className="cat__image">
          <StaticImage src="../assets/images/people/colin.jpg" alt="Colin" />
        </div>
        <h4>Colin</h4>
        <p>Company mascot</p>
      </li>
    </ul>

    <p>
      Between us we have; <strong>lead front-end teams</strong> for the UK
      Governments biggest software engineering department, spoken at (and
      organised!) <strong>international dev conferences</strong>, been among the
      first employees of <strong>pioneering UK startups</strong>,{" "}
      <strong>taught young students</strong> the basics of programming, and
      delivered <strong>consistently successful software</strong> as
      freelancers.
    </p>

    <p className="leads-the-things emboldernised">Our work</p>
    <p>
      We're the best at all things front-end, having worked for many years
      through multiple technology trends. Want us to modernise your existing
      website, create you a <strong>management portal</strong>, or put together
      your <strong>startup's MVP</strong>? No problem!
      <br />
      <br />
      Our current prefered stack utilises;{" "}
      <a href="https://facebook.github.io/react/">React.js</a> &amp;{" "}
      <a href="http://redux.js.org/">Redux</a>, backed by{" "}
      <a href="https://nodejs.org/">Node.js</a> &amp;{" "}
      <a href="https://expressjs.com/">express</a>.
      <br />
      <br />
      This allows us to get up and running in a flash, and create modern,
      performant apps, delivering measureable value.
      <br />
      <br />
      We write all code to a high standard, adhering to industry level code
      quality and style. We always write to the latest JavaScript specification{" "}
      <a href="https://babeljs.io/">(ES7 with Babel)</a>, compile our CSS with{" "}
      <a href="http://sass-lang.com/">Sass</a> and{" "}
      <a href="http://getbem.com/introduction/">BEM</a> or CSS Modules, and use
      appropriate technologies to assure our code is tested and linted
      sufficiently.
    </p>

    <p>
      If you think we could help you too, <a href="/contact">get in touch!</a>
    </p>
  </Layout>
);

export default AboutPage;
